import {
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  PLATFORM_ID,
  inject,
  effect,
  input,
  output,
  viewChild,
  ElementRef,
} from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  standalone: true,
  styleUrls: ['./topbar.component.scss'],
  imports: [RouterModule],
})
export class TopbarComponent {
  private router = inject(Router);

  loading = input<boolean>(false);
  menuActive = input<boolean>(false);
  logoClass = input<string>('');
  menu = input<fromMenu.MenuItem[]>([]);

  toggleOverlay = output<void>();
  closeOverlay = output<void>();
  openOverlay = output<void>();

  private navigationStart$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));

  routerEffect$ = effect(() => {
    const navigationStart = this.navigationStart$();

    if (this.menuActive()) {
      this.closeOverlay.emit();
    }
  });
}
