<div class="main-nav">
  <div class="topbar container" #topbar>
    <h1 class="topbar__logo">
      <a [routerLink]="'/'">
        <div class="topbar__logo__title">Titel</div>
      </a>
    </h1>
    <nav class="topbar__nav">
      <ul class="topbar__nav__list">
        <li class="topbar__nav__list__item">
          <button
            (click)="toggleOverlay.emit()"
            [routerLink]="'/'"
            [routerLinkActive]="'is--current'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            Home
          </button>
        </li>
        @for (page of menu(); track page) {
          <li class="topbar__nav__list__item">
            <button (click)="toggleOverlay.emit()" [routerLink]="page.url" [routerLinkActive]="'is--current'">
              {{ page.title }}
            </button>
          </li>
        }
      </ul>
    </nav>
  </div>
</div>
